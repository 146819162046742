import { Anchor, Avatar, Badge, Box, Button, Center, Divider, Flex, Grid, Group, Paper, Select, Skeleton, Space, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { useDebouncedValue } from "@mantine/hooks";
import { IconArrowDown, IconArrowUp, IconCalendar, IconFileDollar, IconFileInvoice, IconHourglassOff, IconPhoto, IconReceipt2 } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import useSwr from "swr";
import { ClientClassificationApi, DashboardApi } from "../../apis";
import { ApprovedInvoiceChart } from "../../components/dashboard/approved-invoice-chart";
import { HighestBalanceList } from "../../components/dashboard/highest-balance-list";
import { OverdueInformationList } from "../../components/dashboard/overdue-information-list";
import { PendingInvoiceChart } from "../../components/dashboard/pending-invoice-chart";
import { useScreenDetector } from "../../components/screen-detector";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

type IFilter = {
  date: string;
  confirmedStartDate: string;
  confirmedEndDate: string;
  pendingStartDate: string;
  pendingEndDate: string;
  categoryId: string;
};

export function Dashboard() {
  const { classes, theme } = useStyles();
  var day: any = dayjs(new Date()).format();

  const { isMobile } = useScreenDetector();
  const [filter, setFilter] = useState<IFilter>({
    date: day,
    confirmedStartDate: dayjs(new Date()).subtract(14, "day").format(),
    confirmedEndDate: day,
    pendingStartDate: dayjs(new Date()).subtract(14, "day").format(),
    pendingEndDate: day,
    categoryId: "",
  });

  const [queryCategory, setQueryCategory] = useState<any>("");
  const [delay] = useDebouncedValue<any>(queryCategory, 800);

  const { data: CategoryList } = useSwr(
    `/inv/client_classification/select?${JSON.stringify(delay)}`,
    async () => await ClientClassificationApi.select({ isParent: true, limit: 50, query: delay }),
    {
      fallback: [],
    },
  );

  const { data: dataMain } = useSwr(
    `/api/main?${JSON.stringify(filter)}`,
    async () =>
      await DashboardApi.main({
        ...filter,
        date: dayjs(filter.date).format(),
        confirmedStartDate: dayjs(filter.confirmedStartDate).format(),
        confirmedEndDate: dayjs(filter.confirmedEndDate).format(),
        pendingStartDate: dayjs(filter.pendingStartDate).format(),
        pendingEndDate: dayjs(filter.pendingEndDate).format(),
      }),
    {
      fallback: [],
    },
  );

  return (
    <div className={classes.root}>
      <Group align="apart" mb={"xs"}>
        <Text size={"xl"} fw={600}>
          Тоон тойм
        </Text>
        <DatePickerInput
          excludeDate={(date) => {
            return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
          }}
          value={new Date(filter.date)}
          onChange={(e: any) => {
            if (e)
              setFilter({
                ...filter,
                date: e,
              });
            else
              setFilter({
                ...filter,
                date: dayjs().format(),
              });
          }}
          style={{ borderRadius: "5px" }}
          variant="unstyled"
          bg={theme.colors.brand[0]}
          miw={isMobile ? "100%" : "240px"}
          placeholder={dayjs().format("YYYY-MM-DD")}
          leftSection={<IconCalendar size={"1.2rem"} />}
          valueFormat="YYYY-MM-DD"
          mr={isMobile ? 0 : "sm"}
        />
      </Group>
      <Grid>
        {dataMain?.numberSurvey ? (
          dataMain?.numberSurvey
            ?.map((item: any, index: number) => {
              return {
                title: item.name,
                value: item.amount,
                diff: item.changedAmount,
                key: index,
                icon: IconArrowDown,
              };
            })
            .map((stat: any, index: number) => {
              const DiffIcon = stat.diff > 0 ? IconArrowUp : IconArrowDown;

              return (
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
                  <Paper withBorder key={stat.key} radius={"md"}>
                    <Group p={"sm"} pb={"xs"}>
                      {index === 0 && <IconFileInvoice size={"1.2rem"} color={theme.colors.gray[6]} />}
                      {index === 1 && <IconFileDollar size={"1.2rem"} color={theme.colors.gray[6]} />}
                      {index === 2 && <IconHourglassOff size={"1.2rem"} color={theme.colors.gray[6]} />}
                      {index === 3 && <IconReceipt2 size={"1.2rem"} color={theme.colors.gray[6]} />}
                      <Text fz={"sm"} fw={600} c={theme.colors.gray[6]}>
                        {stat.title}
                      </Text>
                    </Group>
                    <Group>
                      <div className={classes.grdnt}></div>
                      <Space />
                      <Text fz={"xl"} fw={700}>
                        {currencyFormat(stat.value)}
                      </Text>
                    </Group>
                    <Group align="right" p={"sm"}>
                      {stat.diff !== 0 && (
                        <>
                          <Text size={"xs"} c={theme.colors.gray[6]}>
                            Өмнөх өдрөөс
                          </Text>
                          <Badge color={stat.diff > 0 ? "green" : "red"} className={stat.diff > 0 ? classes.green : classes.red}>
                            <Flex align="center" gap={8}>
                              <DiffIcon size="0.8rem" stroke={3} />
                              <Text>{currencyFormat(Math.abs(stat.diff))}</Text>
                            </Flex>
                          </Badge>
                        </>
                      )}
                    </Group>
                  </Paper>
                </Grid.Col>
              );
            })
        ) : (
          <>
            {[1, 2, 3, 4].map((index: number) => {
              return (
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
                  <Skeleton h={"121px"} w={"100%"} />
                </Grid.Col>
              );
            })}
          </>
        )}
        <Grid.Col>
          <Space h={"xs"} />
          <PendingInvoiceChart data={dataMain?.paymentPending || []} filter={filter} setFilter={setFilter} />
        </Grid.Col>
        <Grid.Col>
          <Space h={"xs"} />
          <ApprovedInvoiceChart data={dataMain?.confirmed || []} filter={filter} setFilter={setFilter} />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 8 }}>
          <OverdueInformationList data={dataMain?.overdue || []} />
          <Space h={"sm"} />
          <HighestBalanceList data={dataMain?.highestRemainder || []} />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
          <Paper p={"sm"} withBorder>
            <Text fw={600}>Нээлттэй нэхэмжлэх</Text>
            <Flex align="center" gap="sm">
              <Text fw={700} fz="xl">
                {dataMain?.byClassification?.count || 0}
              </Text>
              <Badge color="green">+{dataMain?.byClassification?.changedCount || 0}</Badge>
            </Flex>
            <Divider my={"md"} />
            <Select
              searchValue={queryCategory}
              onSearchChange={(e) => {
                console.log(e);
                setQueryCategory(e);
              }}
              value={filter?.categoryId}
              onChange={(e: string | null) => {
                if (e)
                  setFilter({
                    ...filter,
                    categoryId: e,
                  });
              }}
              searchable
              placeholder="Сонгох"
              data={
                CategoryList?.map((item: any) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                }) || []
              }
            />
            <Space h={"sm"} />
            <Flex direction={"column"} gap={"sm"}>
              {dataMain?.byClassification?.priorities?.map((item: any, index: number) => {
                return (
                  <Flex align="center" key={index}>
                    <Text>{item.name}</Text>
                    <Flex align={"center"} gap={10}>
                      <Text fw={600}>{item.count}</Text>
                      {item.changedCount > 0 ? <Badge color="green">+{item.changedCount} </Badge> : <Badge color="red">{item.changedCount} </Badge>}
                    </Flex>
                  </Flex>
                );
              })}
              <Button radius="md" component={Link} to="/sales-invoice">
                Бүгдийг үзэх
              </Button>
            </Flex>
          </Paper>
          <Space h={"xs"} />
          <Paper p={"sm"} withBorder>
            <Text fz={"lg"} fw={600}>
              Сүүлд төлөгдсөн
            </Text>
            <Divider my={"xs"} />
            <Space h={"sm"} />
            <Flex direction="column" gap="sm" mih={"300px"} justify="space-between">
              {dataMain?.recentlyPaid.length > 0 ? (
                dataMain?.recentlyPaid?.map((item: any, index: number) => {
                  return (
                    <Flex key={index} w={"100%"} gap={"sm"}>
                      <Avatar src={item.invReceiverBusiness?.logo}>
                        <IconPhoto />
                      </Avatar>
                      <Flex align={"center"} direction={"column"} w={"100%"} wrap={"nowrap"}>
                        <Group w={"100%"} align="apart">
                          <Text fw={600}>{item.invReceiverBusiness?.profileName || "-"}</Text>
                          <Text fz={"sm"} c="gray" ta="end">
                            {dateTimeFormat(item.createdAt)}
                          </Text>
                        </Group>

                        <Group align="apart" w={"100%"} mt={"xs"}>
                          <Text fz={"sm"} c="gray">
                            #{item.invReceiverBusiness?.refCode || "-"}
                          </Text>
                          <Text ta="end">{currencyFormat(item.totalAmount)}</Text>
                        </Group>
                      </Flex>
                    </Flex>
                  );
                })
              ) : (
                <Box>
                  <Center>
                    <Text fz={"sm"}>Түүх байхгүй.</Text>
                  </Center>
                </Box>
              )}
              <Anchor size={"sm"} href="/closed-invoice">
                Бүгдийг харах
              </Anchor>
            </Flex>
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  grdnt: {
    background: theme.colors.brand[3],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));
