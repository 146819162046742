import { Anchor, Flex, Group, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconCaretRight } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ColumnType, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IItem } from "../../interfaces/IItem";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { useScreenDetector } from "../screen-detector";

export const OverdueInformationList = ({ data }: { data: any[] }) => {
  const { invoiceStatus = [], invoicePaymentStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);

  const { classes } = useStyles();
  const { isMobile } = useScreenDetector();

  const [filters] = useState({
    query: "",
    isReceived: false,
  });

  const columns = useHeader({
    myBusinessType,
    invoicePaymentStatus: invoicePaymentStatus,
    invoiceStatus: invoiceStatus,
  });

  return (
    <div>
      <Paper withBorder py={"4px"}>
        <Flex align="center" gap="sm">
          <Flex align="center" gap="sm">
            <div className={classes.grdnt}></div>
            <Text component="h1">Хугацаа хэтрэлтийн мэдээлэл</Text>
          </Flex>
          {!isMobile ? (
            <Anchor size={"xs"} px={"xs"} href="/sales-invoice">
              Бүгдийг харах
            </Anchor>
          ) : (
            <Anchor px={"xs"} href="/sales-invoice">
              <IconCaretRight />
            </Anchor>
          )}
        </Flex>
        <Paper px={"lg"} style={{ overflow: "auto" }}>
          <Table name="overdue.information.list" columns={columns} filters={{ ...filters }} dataSource={data} />
        </Paper>
      </Paper>
    </div>
  );
};

const useHeader = ({
  myBusinessType = "",
  invoicePaymentStatus = [],
  invoiceStatus = [],
}: {
  myBusinessType: string;
  invoicePaymentStatus: IReference[];
  invoiceStatus: IReference[];
}): ColumnType<IItem | any>[] => [
  {
    title: "Төлөлт хэтэрсэн",
    render: (record) => {
      return record.name;
    },
  },
  {
    title: "Нэхэмжлэх тоо",
    render: (record) => {
      return <Text>{record.invoiceCount} ш</Text>;
    },
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    render: (record) => {
      return (
        <Group align="right">
          <Text>{currencyFormat(record.amountToPay)}</Text>
        </Group>
      );
    },
  },
  {
    title: "Бизнес тоо",
    render: (record) => <Text w="max-content">{record.businessCount}</Text>,
  },
  {
    title: "Дундаж үлдэгдэл",
    render: (record) => (
      <Group align="right">
        <Text>{currencyFormat(record.averageAmount || 0)}</Text>
      </Group>
    ),
  },
];

const useStyles = createStyles((theme) => ({
  grdnt: {
    background: theme.colors.brand[3],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
}));
